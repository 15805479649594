import React, { useEffect, useRef } from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import { RichText } from "prismic-reactjs"

import ContentBlock from "../components/content-block"
import VideoBlock from "../components/video-block"
import { useOnLoadImages } from "../hooks/useOnLoadImages"
import Preloader from "../components/preloader"
import Carousel from "../components/carousel"

const BlogPage: React.FC = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "blog",
        page_path: "/blog",
        send_to: "G-LB7ZYCKNWC",
      })
    }
  }, [])
  const wrapperRef = useRef<HTMLDivElement>(null)
  const imagesLoaded = useOnLoadImages(wrapperRef)
  const data = useStaticQuery(graphql`
    query {
      prismicBlogPage {
        data {
          title_keywords {
            keyword {
              richText
            }
          }
          body {
            ... on PrismicBlogPageDataBodyContentBlock {
              slice_type
              primary {
                header {
                  text
                }
                text {
                  richText
                }
                image {
                  alt
                  url(imgixParams: { width: 1280 })
                }
                image_width
                image_link {
                  url
                }
              }
            }
            ... on PrismicBlogPageDataBodyVideoBlock {
              slice_type
              primary {
                header {
                  text
                }
                text {
                  richText
                }
                video {
                  embed_url
                }
              }
            }
          }
        }
      }
    }
  `)
  const pageData = data.prismicBlogPage.data
  const slices = pageData.body
  const keywordSlides = pageData.title_keywords.map(node => {
    return { prismicRichText: node.keyword.richText }
  })

  return (
    <div ref={wrapperRef}>
      {!imagesLoaded && <Preloader />}
      <Layout>
        <Carousel slides={keywordSlides} fade={false} justText />

        {slices.map((slice, i) => {
          if (slice.slice_type === "content_block") {
            return (
              <ContentBlock
                key={i}
                header={slice.primary.header.text}
                text={slice.primary.text.richText}
                imageUrl={slice.primary.image.url}
                imageLink={slice.primary.image_link.url}
                imageWidth={slice.primary.image_width}
                rowReversed={i % 2 == 0}
                rowsOnLgScreen
              />
            )
          } else if (slice.slice_type === "video_block") {
            return (
              <VideoBlock
                key={i}
                header={slice.primary.header.text}
                text={slice.primary.text.richText}
                video={slice.primary.video.embed_url}
                rowReversed={i % 2 == 0}
                rowsOnLgScreen
              />
            )
          } else return null
        })}
      </Layout>
    </div>
  )
}

export default BlogPage
